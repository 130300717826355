import React from "react";
import ConsultantImage from "../Assets/ConsultantImage.png";

function Home(props) {
  return (
    <div id="homePage">
      <section className="text-gray-600 body-font">
        <div className="mx-auto flex py-6 items-center justify-center flex-col bg-gray-500  my-10">
          <div className="text-center lg:w-2/3 w-full">
            <img
              className="h-128 w-128 rounded justify-center text-center my-6 mx-auto"
              src={ConsultantImage}
              alt="/"
            />
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-100 mt-4">
              SOLUTIONS
            </h1>
            <p className="mb-8 leading-relaxed text-gray-100">
              Research and Development, Full stack development and Energy
              management
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
