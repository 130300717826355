import React from "react";
// import javascriptlogo from "../Assets/Javascript.png";
import ServicesIcon from "../Assets/ServicesIcon.png";
import RandDLogo from "../Assets/RandD.png";
// import reactjslogo from "../Assets/ReactJS.png";
import ContactIcon from "../Assets/ContactIcon.png";
import fullstacklogo from "../Assets/FullStack.png";

function About(props) {
  return (
    <div id="aboutPage">
      <section className="text-gray-600 body-font bg-gray-700">
        <div className="container px-5 py-12 mx-auto">
          <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
            <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-gray-100 text-indigo-500 flex-shrink-0">
              <img
                className="sm:w-16 sm:h-16 w-10 h-10"
                src={RandDLogo}
                alt="/"
              />
            </div>
            <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
              <h2 className="text-gray-300 text-lg title-font font-medium mb-2">
                R&D
              </h2>
              <p className="leading-relaxed text-base text-gray-300 ">
                We enhance decision-making and accelerate your growth with
                trusted research and development. We help streamline your
                business operations and manage risks with a one-stop
                intelligence source.
              </p>
            </div>
          </div>
          <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
            <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
              <h2 className="text-gray-300 text-lg title-font font-medium mb-2">
                We provide software products and services
              </h2>
              <p className="leading-relaxed text-base text-gray-300 ">
                <ul className="space-y-1 list-disc list-inside">
                  <li className="py-2">
                    Development - With the specs finalised, our development team
                    begin iterating through the feature list, developing the
                    software and conducting rigourous testing to ensure quality
                    in-production.
                  </li>
                  <li className="py-2">
                    Specification - at Curie, it all begins with a
                    specification. We prepare a functional and technical
                    specification detailing the exact backlog of features
                    required to complete delivery.
                  </li>
                  <li className="py-2">
                    Analysis & Operations - our Analysis and DevOps team
                    monitors the development process throughout, and are able to
                    provide documentation and analysis throughout the process,
                    ensuring quality in-use.
                  </li>
                </ul>
              </p>
            </div>
            <div className="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-gray-100 text-indigo-500 flex-shrink-0">
              <img
                className="sm:w-16 sm:h-16 w-10 h-10"
                src={ServicesIcon}
                alt="/"
              />
            </div>
          </div>
          <div className="flex items-center lg:w-3/5 mx-auto sm:flex-row flex-col">
            <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-gray-100 text-indigo-500 flex-shrink-0">
              <img
                className="sm:w-16 sm:h-16 w-10 h-10"
                src={ContactIcon}
                alt="/"
              />
            </div>
            <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
              <h2 className="text-gray-300 text-lg title-font font-medium mb-2">
                We are passionate about the environment. Contact us to help you
                design your green energy solution
              </h2>
              <p className="leading-relaxed text-base text-gray-300 ">
                Energy audits, energy efficiency, demand management and solar PV
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="text-gray-600 body-font">
        <div className="container mx-auto flex py-6 items-center justify-center flex-col bg-slate-600 rounded-xl my-10">
          <div className="text-center lg:w-2/3 w-full">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-300 mt-4">
              R&D
            </h1>
            <p className="mb-8 leading-relaxed text-gray-300">
              We enhance decision-making and accelerate your growth with trusted
              research and development. We help streamline your business
              operations and manage risks with a one-stop intelligence source.
            </p>
          </div>
        </div>
      </section> */}
      {/* This is where the second section starts */}
      {/* <section className="text-gray-600 body-font">
        <div className="container mx-auto flex py-6 items-center justify-center flex-col bg-slate-600 rounded-xl my-10">
          <div className=" lg:w-2/3 w-full">
            <h1 className="text-center title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-300 mt-4">
              We provide software products and services
            </h1>
            <p className="mb-8 leading-relaxed text-gray-300">
              <ul className="space-y-1 list-disc list-inside pl-3">
                <li className="py-2">
                  Development - With the specs finalised, our development team
                  begin iterating through the feature list, developing the
                  software and conducting rigourous testing to ensure quality
                  in-production.
                </li>
                <li className="py-2">
                  Specification - at Curie, it all begins with a specification.
                  We prepare a functional and technical specification detailing
                  the exact backlog of features required to complete delivery.
                </li>
                <li className="py-2">
                  Analysis & Operations - our Analysis and DevOps team monitors
                  the development process throughout, and are able to provide
                  documentation and analysis throughout the process, ensuring
                  quality in-use.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default About;
