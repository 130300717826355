import React from "react";
import curielogo from "../Assets/curielogo.png";

function Footer(props) {
  return (
    <div className="bg-gray-800">
      <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
        <div className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
          <div>
            <img className=" h-16 w-16" src={curielogo} alt="/" />
          </div>
          <span className="ml-3 text-xl text-gray-300">CurieGroup</span>
        </div>
        <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
          © 2020 CurieGroup — @curi3
        </p>
      </div>
    </div>
  );
}

export default Footer;
