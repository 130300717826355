import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createCandidate } from "../graphql/mutations";

function ContactUs(props) {
  // ________________________________________________________________;
  const [newData, setNewData] = useState({
    name: "",
    email: "",
    message: "",
  });
  //________________________________________________________________
  const handleCreateNewMessage = async () => {
    var mynewmesssage = {
      name: newData.name,
      email: newData.email,
      message: newData.message,
    };

    console.log(mynewmesssage);

    await API.graphql(
      graphqlOperation(createCandidate, { input: mynewmesssage })
    );

    setNewData({
      name: "",
      email: "",
      message: "",
    });
  };
  // ________________________________________________________________;
  return (
    <div className="mt-6">
      {/* //________________________________________________________________________ */}
      <section
        id="contactUsPage"
        className="flex items-center justify-center pb-12"
      >
        <div className="container mx-auto ">
          <div className=" bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0  shadow-md">
            <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
              Feedback
            </h2>
            {/* //________________________________________________________________________ */}
            <p className="leading-relaxed mb-5 text-gray-600">
              If you have any queries, please feel free to contact us using the
              form bellow, we'll be sure to get back to you as soon as possible!
            </p>
            {/* //________________________________________________________________________ */}
            <div className="relative mb-4">
              <label className="leading-7 text-sm text-gray-600">Name</label>
              <input
                type="text"
                value={newData.name}
                onChange={(e) =>
                  setNewData({
                    ...newData,
                    name: e.target.value,
                  })
                }
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            {/* //________________________________________________________________________ */}
            <div className="relative mb-4">
              <label className="leading-7 text-sm text-gray-600">Email</label>
              <input
                type="text"
                value={newData.email}
                onChange={(e) =>
                  setNewData({
                    ...newData,
                    email: e.target.value,
                  })
                }
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            {/* //________________________________________________________________________ */}
            <div className="relative mb-4">
              <label className="leading-7 text-sm text-gray-600">Message</label>
              <textarea
                type="text"
                value={newData.message}
                onChange={(e) =>
                  setNewData({
                    ...newData,
                    message: e.target.value,
                  })
                }
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              ></textarea>
            </div>
            {/* //________________________________________________________________________ */}
            <button
              onClick={handleCreateNewMessage}
              className="text-white bg-slate-700  border-0 py-2 px-6 focus:outline-none hover:bg-slate-500  rounded text-lg"
            >
              Send
            </button>
            <p className="text-xs text-gray-500 mt-3">
              Chicharrones blog helvetica normcore iceland tousled brook viral
              artisan.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactUs;
