import React, { useState, useEffect, useRef } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import curielogo from "./Assets/curielogo.png";
import { BrowserRouter } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import ContactUs from "./Components/ContactUs";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Amplify from "aws-amplify";
import config from "./aws-exports";
// import { withAuthenticator } from "@aws-amplify/ui-react";

Amplify.configure(config);

function App() {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };
  //Below is the code for collapsing a navbar ______________________________________________________________________________________
  let menuRef = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setNav(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);
  // ______________________________________________________________________________________
  return (
    <BrowserRouter>
      <div>
        <div className="flex justify-between items-center h-24  mx-auto px-4 text-black bg-slate-700 rounded-b">
          {/*max-w-[1240px]*/}
          <div className="flex justify-center items-center">
            <img className="h-18 w-16 mr-3 mx-auto" src={curielogo} alt="/" />
            <h1 className="h-12 w-64 mt-3 text-white text-2xl">Curie Group</h1>
          </div>

          <div
            className={
              nav ? "invisible" : "w-full text-2xl text-white font-medium"
            }
          ></div>

          <ul className="hidden md:flex text-white">
            <Link to="#homePage" smooth>
              <button>
                <li className="p-4 hover:duration-300 hover:scale-110">Home</li>
              </button>
            </Link>

            <Link to="#aboutPage" smooth>
              <button>
                <li className="p-4 hover:duration-300 hover:scale-110">
                  About
                </li>
              </button>
            </Link>
            <Link to="#contactUsPage" smooth>
              <button>
                <li className="p-4 hover:duration-300 hover:scale-110">
                  Contact
                </li>
              </button>
            </Link>
          </ul>
          {/* ______________________________________________________________________________________ */}
          <div onClick={handleNav} className="block md:hidden ">
            {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
          </div>
          {/* ______________________________________________________________________________________ */}
        </div>

        {/*_______________________________________________________________________________________*/}
        <div
          ref={menuRef}
          className={
            nav
              ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 ease-in-out duration-500 md:hidden bg-black rounded-r z-10"
              : "fixed left-[-100%]"
          }
        >
          <div className="flex items-center justify-center">
            <img
              className="h-18 w-32 bg-transparent mb-2"
              src={curielogo}
              alt="/"
            />
          </div>

          <ul className=" p-4 grid grid-cols-1 text-center">
            <Link to="#homePage" smooth>
              <button onClick={() => setNav(false)}>
                <li className="p-4 border-b border-gray-600 text-white">
                  Home
                </li>
              </button>
            </Link>

            <Link to="#aboutPage" smooth>
              <button onClick={() => setNav(false)}>
                <li className="p-4 border-b border-gray-600 text-white">
                  About
                </li>
              </button>
            </Link>
            <Link to="#contactUsPage" smooth>
              <button onClick={() => setNav(false)}>
                <li className="p-4 border-b border-gray-600 text-white">
                  Contact
                </li>
              </button>
            </Link>
          </ul>
        </div>
      </div>
      <div>
        <Home />
        <About />
        <ContactUs />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
